import React from 'react'
import Layout from '../components/layout'

const PrivacyPolicy = () => (
  <Layout>
    <div className="l-main is-single-article">
      <main>
        <article className="article">
          <p className="article__pub-date">2020.9.27</p>
          <h1 className="article__title">プライバシーポリシー</h1>
          <div className="article__body">
            <div className="content">
              <h2>広告の配信について</h2>
              <p>当サイトでは、第三者配信の広告サービス「Google アドセンス」を利用しています。</p>
              <p>第三者配信事業者は、サイト利用者の興味に応じた商品やサービスの広告（パーソナライズ広告）を表示するため、Cookie（クッキー）を使用しています。</p>
              <p>サイト利用者は、<a href="https://adssettings.google.com/authenticated">広告設定</a>でパーソナライズ広告を無効にすることができます。また、<a href="http://www.aboutads.info/choices/">www.aboutads.info</a> にアクセスすれば、パーソナライズ広告に使われる第三者配信事業者の Cookie を無効にできます。</p>
              <p>Cookie（クッキー）を無効にする方法や Google アドセンスに関する詳細は「<a href="https://policies.google.com/technologies/ads?gl=jp">広告 – ポリシーと規約 – Google</a>」をご確認ください。</p>

              <h2>アクセス解析ツールについて</h2>
              <p>当ブログでは、Google によるアクセス解析ツール「Google アナリティクス」を利用しています。</p>
              <p>この Google アナリティクスはトラフィックデータの収集のために Cookie（クッキー）を使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>
              <p>この機能は Cookie（クッキー）を無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。詳しくは<a href="https://marketingplatform.google.com/about/analytics/terms/jp/">利用規約</a>をご覧ください。</p>

              <h2>免責事項</h2>
              <p>当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。</p>
              <p>また当サイトのコンテンツ・情報について、できる限り正確な情報を提供するように努めていますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。</p>
              <p>当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>

              <h2>著作権について</h2>
              <p>当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。</p>
              <p>当サイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、各権利所有者様本人が直接、<a href="https://twitter.com/_Masahiro_H_">こちらの Twitter アカウント</a>より DM にてご連絡ください。確認後、対応いたします。</p>

              <h2>リンクについて</h2>
              <p>当サイトは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。</p>
            </div>
          </div>
        </article>
      </main>
    </div>
  </Layout>
)

export default PrivacyPolicy
